<template>
  <svg
    width="24"
    height="30"
    viewBox="0 0 24 30"
    class="icon-A"
    style="transform: translate(-0.5px, -24px)"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        class="pin"
        fill="#00518F"
        d="M12 0C5.4 0 0 5.09 0 11.354c0 2.48.867 4.829 2.4 6.787l9.067 11.615c.2.26.666.326.933.13l.133-.13L21.6 18.14c1.533-1.958 2.4-4.307 2.4-6.787C24 5.09 18.6 0 12 0z"
        transform="translate(-300 -357) translate(248 198) translate(26 135) translate(26 24)"
      ></path>
      <text
        fill="#FFF"
        text-anchor="middle"
        font-family="ProximaNova-Bold, Proxima Nova"
        font-size="16"
        font-weight="bold"
        transform="translate(-300 -357) translate(248 198) translate(26 135) translate(26 24)"
      >
        <tspan x="12" y="17">{{ value }}</tspan>
      </text>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    num: Number,
  },
  computed: {
    value() {
      if (this.num) return this.num;
      return "A";
    },
  },
};
</script>

<style lang="scss" scoped>
</style>