import Vue from 'vue';
import VueRouter from 'vue-router';

import ModeSelection from '@/views/ModeSelection.vue';
import LandingSurveyRedirect from '@/views/LandingSurveyRedirect.vue';

import SubscriptionsScreen from '@/views/demo/SubscriptionsScreen.vue';
import FleetsScreen from '@/views/demo/FleetsScreen.vue';
import MapScreen from '@/views/demo/MapScreen.vue';
import VehiclesScreen from '@/views/demo/VehiclesScreen.vue';
import DriversScreen from '@/views/demo/DriversScreen.vue';
// import ManagersScreen from '@/views/demo/ManagersScreen.vue';
import VehicleDetailsScreen from '@/views/demo/VehicleDetailsScreen.vue';
import DriverDetailsScreen from '@/views/demo/DriverDetailsScreen.vue';
import ManagerDetailsScreen from '@/views/demo/ManagerDetailsScreen.vue';
import PerformanceScreen from '@/views/demo/PerformanceScreen.vue';
import ReportsScreen from '@/views/demo/ReportsScreen.vue';
// import OverviewScreen from '@/views/demo/OverviewScreen.vue';
import NotificationSettings from '@/views/demo/NotificationSettings.vue';
import SetNotification from '@/views/demo/SetNotification.vue';
import AllNotifications from '@/views/demo/AllNotifications.vue';
import AddOnSolutionsScreen from "@/views/demo/AddOnSolutionsScreen.vue";

// import GameWelcome from '@/views/game/GameWelcome.vue';
// import Challenges from '@/views/game/Challenges.vue';
// import ThankYou from '@/views/game/ThankYou.vue';
// import Congrats from '@/views/game/Congrats.vue';
// import Leaderboard from '@/views/game/Leaderboard.vue';
// import WelcomeVideo from '@/views/game/WelcomeVideo.vue';
import HomeScreen from '../views/demo/HomeScreen.vue';
import InVehicleCoaching from '../views/demo/InVehicleCoaching.vue';
import FleetAdapter from '../views/demo/FleetAdapter.vue';
import ExpensesScreen from '../views/demo/ExpensesScreen.vue';
import PlugAndCharge from '../views/demo/PlugAndCharge.vue';
import MyBA from '../views/MyBA.vue';
import ADASInsights from '../views/demo/ADASInsights.vue';
import GuidedTourSplash from '../views/GuidedTourSplash.vue';
import RecallVehicles from '../views/demo/RecallVehicles.vue';
import LytxCamera from '../views/demo/LytxCamera.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: "/dealer",
    name: "map-screen--dealer",
    component: MapScreen,
    meta: { showNavigation: true, label: "Map", mode: 3 },
  },
  {
    path: "/0",
    name: "map-screen",
    component: MapScreen,
    meta: { showNavigation: true, label: "Map", mode: 1 },
  },
  {
    path: "/1/detail/:id",
    name: "vehicle-detail",
    props: true,
    component: VehicleDetailsScreen,
    meta: { showNavigation: true },
  },
  {
    path: "/1",
    name: "vehicles-screen",
    component: VehiclesScreen,
    meta: { showNavigation: true, label: "Vehicles" },
  },
  {
    path: "/2/detail/:id",
    name: "driver-detail",
    props: true,
    component: DriverDetailsScreen,
    meta: { showNavigation: true },
  },
  {
    path: "/2",
    name: "drivers-screen",
    component: DriversScreen,
    meta: { showNavigation: true, label: "Drivers" },
  },
  {
    path: "/3/detail/:id",
    name: "manager-detail",
    props: true,
    component: ManagerDetailsScreen,
    meta: { showNavigation: true },
  },
  // {
  //   path: "/3",
  //   name: "managers-screen",
  //   component: ManagersScreen,
  //   meta: { showNavigation: true, label: "Managers" },
  // },
  {
    path: "/4",
    name: "performance-screen",
    component: PerformanceScreen,
    meta: { showNavigation: true, label: "Performance" },
  },
  {
    path: "/5",
    name: "reports-screen",
    component: ReportsScreen,
    meta: { showNavigation: true, label: "Reports" },
  },
  {
    path: "/7/set/:type?/:editing?",
    name: "set-notification",
    props: true,
    component: SetNotification,
    meta: { showNavigation: true },
  },
  {
    path: "/7",
    name: "notification-settings",
    component: NotificationSettings,
    meta: { showNavigation: true, label: "Fleet Settings" },
  },
  {
    path: "/all-notifications",
    name: "all-notifications",
    component: AllNotifications,
    meta: { showNavigation: true, label: "Notifications Center" },
  },
  {
    path: "/8",
    name: "add-on-solutions",
    component: AddOnSolutionsScreen,
    meta: { showNavigation: true, label: "Add-on Solutions" },
  },
  {
    path: "/8/detail/ivc",
    name: "in-vehicle-coaching",
    component: InVehicleCoaching,
    meta: { showNavigation: true, label: "In-Vehicle Coaching" },
  },
  {
    path: "/8/detail/adapter",
    name: "fleet-adapter",
    component: FleetAdapter,
    meta: { showNavigation: true, label: "Fleet Adapter" },
  },
  {
    path: "/8/detail/evgo",
    name: "plug-and-charge",
    component: PlugAndCharge,
    meta: { showNavigation: true, label: "Plug and Charge" },
  },
  {
    path: "/8/detail/adas",
    name: "adas-insights",
    component: ADASInsights,
    meta: { showNavigation: true, label: "ADAS Insights" },
  },
  {
    path: "/8/detail/camera",
    name: "camera-guide",
    component: LytxCamera,
    meta: { showNavigation: true, label: "OnStar DualCam" },
  },
  {
    path: "/9",
    name: "fleets",
    component: FleetsScreen,
    meta: { showNavigation: true, label: "Fleets" },
  },
  {
    path: "/10",
    name: "subscriptions",
    component: SubscriptionsScreen,
    meta: { showNavigation: true, label: "Subscriptions" },
  },
  {
    path: "/home",
    name: "home",
    component: HomeScreen,
    meta: { showNavigation: true, label: "Home", mode: 1 },
    alias: "/6",
  },
  {
    path: "/essentials",
    name: "essentials",
    component: HomeScreen,
    meta: { showNavigation: true, label: "Home", mode: 1, inEssentialsMode: true },
  },
  {
    path: "/3",
    name: "expenses",
    component: ExpensesScreen,
    meta: { showNavigation: true, label: "Expenses" },
  },
  {
    path: "/11",
    name: "recall-vehicles",
    component: RecallVehicles,
    meta: { showNavigation: true, label: "Recall Vehicles" },
  },
  {
    path: "/",
    name: "survey-redirect",
    component: LandingSurveyRedirect,
  },
  {
    path: "/welcome",
    name: "mode-selection",
    component: ModeSelection,
  },
  {
    path: "/myba",
    name: "myba",
    component: MyBA,
  },
  {
    path: "/tour",
    name: "tour",
    component: GuidedTourSplash,
  },
  // {
  //   path: "/game",
  //   name: "game",
  //   component: WelcomeVideo,
  // },
  // {
  //   path: "/game-video-end",
  //   name: "game-video-end",
  //   component: GameWelcome,
  // },
  // {
  //   path: "/challenges",
  //   name: "challenges",
  //   component: Challenges,
  // },
  // {
  //   path: "/leaderboard",
  //   name: "leaderboard",
  //   component: Leaderboard,
  // },
  // {
  //   path: "/thank-you",
  //   name: "thank-you",
  //   component: ThankYou,
  // },
  // {
  //   path: "/congrats",
  //   name: "congrats",
  //   component: Congrats,
  // },
  // {
  //   path: "/where-is-watson",
  //   name: "where-is-watson",
  //   component: MapScreen,
  //   meta: { showNavigation: true, mode: 2 },
  // },
  // {
  //   path: "/fosters-enlightenment",
  //   name: "fosters-enlightenment",
  //   component: MapScreen,
  //   meta: { showNavigation: true, mode: 2, questionAnswer: true },
  // },
  // {
  //   path: "/unlock-productivity",
  //   name: "unlock-productivity",
  //   component: MapScreen,
  //   meta: { showNavigation: true, mode: 2 },
  // },
  // {
  //   path: "/lets-get-real",
  //   name: "lets-get-real",
  //   component: MapScreen,
  //   meta: { showNavigation: true, mode: 2 },
  // },
  // {
  //   path: "/be-a-know-it-all",
  //   name: "be-a-know-it-all",
  //   component: MapScreen,
  //   meta: { showNavigation: true, mode: 2 },
  // },
  // {
  //   path: "/about-your-driving",
  //   name: "about-your-driving",
  //   component: MapScreen,
  //   meta: { showNavigation: true, mode: 2, questionAnswer: true },
  // },
  // {
  //   path: "/what-a-trip",
  //   name: "what-a-trip",
  //   component: MapScreen,
  //   meta: { showNavigation: true, mode: 2, questionAnswer: true },
  // },
  // {
  //   path: "/trips-dont-lie",
  //   name: "trips-dont-lie",
  //   component: MapScreen,
  //   meta: { showNavigation: true, mode: 2 },
  // },
  // {
  //   path: "/presto-changeo",
  //   name: "presto-changeo",
  //   component: MapScreen,
  //   meta: { showNavigation: true, mode: 2 },
  // },
  // {
  //   path: "/monitor-what-matters",
  //   name: "monitor-what-matters",
  //   component: MapScreen,
  //   meta: { showNavigation: true, mode: 2, questionAnswer: true },
  // },
  // {
  //   path: "/see-the-big-picture",
  //   name: "see-the-big-picture",
  //   component: MapScreen,
  //   meta: { showNavigation: true, mode: 2 },
  // },
  // {
  //   path: "/easy-to-prepare",
  //   name: "easy-to-prepare",
  //   component: MapScreen,
  //   meta: { showNavigation: true, mode: 2 },
  // },
  // {
  //   path: "/dodge-down-time",
  //   name: "dodge-down-time",
  //   component: MapScreen,
  //   meta: { showNavigation: true, mode: 2, questionAnswer: true },
  // },
  // {
  //   path: "/delegate",
  //   name: "delegate",
  //   component: MapScreen,
  //   meta: { showNavigation: true, mode: 2 },
  // },
  // {
  //   path: "/whos-your-top-driver",
  //   name: "whos-your-top-driver",
  //   component: MapScreen,
  //   meta: { showNavigation: true, mode: 2 },
  // },
  // {
  //   path: "/be-on-the-alert",
  //   name: "be-on-the-alert",
  //   component: MapScreen,
  //   meta: { showNavigation: true, mode: 2 },
  // },
  // {
  //   path: "/turn-by-turn-turner",
  //   name: "turn-by-turn-turner",
  //   component: MapScreen,
  //   meta: { showNavigation: true, mode: 2 },
  // },
  // {
  //   path: "/not-buckled-not-cool",
  //   name: "not-buckled-not-cool",
  //   component: MapScreen,
  //   meta: { showNavigation: true, mode: 2 },
  // },
  // {
  //   path: "/not-so-fast",
  //   name: "not-so-fast",
  //   component: MapScreen,
  //   meta: { showNavigation: true, mode: 2 },
  // },
  // {
  //   path: "/dont-go-too-far",
  //   name: "dont-go-too-far",
  //   component: MapScreen,
  //   meta: { showNavigation: true, mode: 2 },
  // },
  // {
  //   path: "/whats-the-diagnosis",
  //   name: "whats-the-diagnosis",
  //   component: MapScreen,
  //   meta: { showNavigation: true, mode: 2 },
  // },
  // {
  //   path: "/go-the-extra-mile",
  //   name: "go-the-extra-mile",
  //   component: MapScreen,
  //   meta: { showNavigation: true, mode: 2 },
  // },
  // {
  //   path: "/need-an-oil-change",
  //   name: "need-an-oil-change",
  //   component: MapScreen,
  //   meta: { showNavigation: true, mode: 2 },
  // },
  // {
  //   path: "/its-great-to-rotate",
  //   name: "its-great-to-rotate",
  //   component: MapScreen,
  //   meta: { showNavigation: true, mode: 2 },
  // },
  // {
  //   path: "/your-fleet-your-bill",
  //   name: "your-fleet-your-bill",
  //   component: MapScreen,
  //   meta: { showNavigation: true, mode: 2 },
  // },
  {
    path: "*",
    name: "map-screen",
    component: MapScreen,
    meta: { showNavigation: true, label: "Map", mode: 1 },
  },
];

const router = new VueRouter({
	mode: 'history',
	routes,
});


export default router;
