<template>
  <div class="dashboard-area" ref="dashboard_area" @scroll="onScroll">
    
    <slot name="grid-top" />

    <!-- Driver Behavior -->
    <div class="card lg" v-show="measuresToDisplay.indexOf(7) !== -1">
      <div class="card-top">
        <span class="title">{{ $t("message.driverBehavior") }}</span>
        <span class="dots">
          <img src="@/assets/more_horiz.svg?external" />
        </span>
      </div>
      <MeasureDaysSelect v-model="behaviorDays" />
      <!-- <div class="flex">
        <div
          class="card-top-subtitle"
          :class="{selected: behaviorDays === 7}"
          @click="behaviorDays = 7"
        >Last 7 Days</div>
        <div class="card-top-subtitle" style="margin: 6px 6px 0 6px; cursor: unset;">|</div>
        <div
          class="card-top-subtitle"
          :class="{selected: behaviorDays === 30}"
          @click="behaviorDays = 30"
        >Last 30 Days</div>
      </div> -->
      <div class="flex">
        <div class="wide-stat">
          <h3>{{ $t("message.topPerformingDrivers") }}</h3>
          <div class="body vertical">
            <div
              class="leaderboard-grid"
              v-for="(driver, index) of reverseSortedDrivers"
              v-show="index < 5"
              :key="'D' + index"
            >
              <div class="circle purple">
                {{ $t("message." + driver["Image"]) }}
              </div>
              <span style="font-weight: 600">{{
                $t("message." + camelize(driver["Name"]))
              }}</span>
              <span
                style="font-size: 21px; font-weight: 800"
                :style="{
                  color: scoreColor(driver.details.score[behaviorDays + 'Day']),
                }"
                >{{ driver.details.score[behaviorDays + "Day"] }}</span
              >
            </div>
          </div>
        </div>
        <div class="wide-stat-divider" />
        <div class="wide-stat">
          <h3>{{ $t("message.lowestPerformingDrivers") }}</h3>
          <div class="body vertical">
            <div
              class="leaderboard-grid"
              v-for="(driver, index) of sortedDrivers"
              v-show="index < 5"
              :key="'D' + index"
            >
              <div class="circle purple">
                {{ $t("message." + driver["Image"]) }}
              </div>
              <span style="font-weight: 600">{{
                $t("message." + camelize(driver["Name"]))
              }}</span>
              <span
                style="font-size: 21px; font-weight: 800"
                :style="{
                  color: scoreColor(driver.details.score[behaviorDays + 'Day']),
                }"
                >{{ driver.details.score[behaviorDays + "Day"] }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Fuel Economy -->
    <div class="card lg" v-show="measuresToDisplay.indexOf(4) !== -1">
      <div class="card-top">
        <span class="title">{{ $t("message.fuelEconomy") }}</span>
        <span class="dots">
          <img src="@/assets/more_horiz.svg?external" />
        </span>
      </div>
      <MeasureDaysSelect v-model="economyDays" />
      <div class="flex">
        <div class="wide-stat">
          <h3>{{ $t("message.topPerformingVehicles") }}</h3>
          <div class="body vertical">
            <div
              class="leaderboard-grid fuel-economy"
              v-for="(vehicle, index) of topVehicles"
              :key="'E' + index"
            >
              <img
                :src="require('@/assets/' + vehicle['Image'] + '?external')"
              />
              <div class="flex column">
                <span
                  style="font-size: 16px; font-weight: 600; line-height: 18px"
                  >{{ vehicle["Vehicle Label"] }}</span
                >
                <span
                  style="font-size: 12px; color: var(--grey); line-height: 16px"
                  >{{ vehicle["Model Name"] }}</span
                >
              </div>
              <div class="flex">
                <div
                  style="
                    font-size: 21px;
                    font-weight: 800;
                    width: 28px;
                    text-align: right;
                    margin-right: 12px;
                  "
                >
                  {{
                    conversions.getMpgValue(
                      vehicle.details.fuelEconomy,
                      $i18n.locale
                    )
                  }}
                </div>
                <div
                  style="
                    display: flex;
                    align-items: flex-end;
                    line-height: 26px;
                  "
                >
                  {{
                    $i18n.locale !== "en"
                      ? $t("message.litersPer100km")
                      : $t("message.mpg")
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="wide-stat-divider" />
        <div class="wide-stat">
          <h3>{{ $t("message.lowestPerformingVehicles") }}</h3>
          <div class="body vertical">
            <div
              class="leaderboard-grid fuel-economy"
              v-for="(vehicle, index) of bottomVehicles"
              :key="'E' + index"
            >
              <img
                :src="require('@/assets/' + vehicle['Image'] + '?external')"
              />
              <div class="flex column">
                <span style="font-size: 16px; font-weight: 600">{{
                  vehicle["Vehicle Label"]
                }}</span>
                <span style="font-size: 12px; color: var(--grey)">{{
                  vehicle["Model Name"]
                }}</span>
              </div>
              <div class="flex">
                <div
                  style="
                    font-size: 20px;
                    font-weight: 800;
                    width: 40px;
                    text-align: right;
                    margin-right: 12px;
                  "
                >
                  {{
                    conversions.getMpgValue(
                      vehicle.details.fuelEconomy,
                      $i18n.locale
                    )
                  }}
                </div>
                <div
                  style="
                    display: flex;
                    align-items: flex-end;
                    line-height: 28px;
                  "
                >
                  {{
                    $i18n.locale !== "en"
                      ? $t("message.litersPer100km")
                      : $t("message.mpg")
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Vehicles Snapshot -->
    <div class="card md snapshot card-vehicles-snapshot" v-show="measuresToDisplay.indexOf(2) !== -1">
      <div class="card-top">
        <span class="title">{{ $t("message.vehiclesSnapshot") }}</span>
        <span class="dots">
          <img src="@/assets/more_horiz.svg?external" />
        </span>
      </div>
      <DonutChart :chartTotal="dataset.dashboard[mode].vehicleCount" showChartKey
        :chartKey="dataset.dashboard[mode].snapshotBreakdown" />
    </div>

    <!-- Recall Vehicles -->
    <div class="card sm" v-show="measuresToDisplay.indexOf(9) !== -1" @click="$router.push('/11')" style="cursor: pointer">
      <div class="card-top">
        <span class="title">{{ $t("message.recallVehicles") }}</span>
        <span class="dots">
          <img src="@/assets/more_horiz.svg?external" />
        </span>
      </div>
      <div class="vertical">
        <BasicMeasure
          :number="2"
          :label="$t('message.vehicles')"
          recallIcon
        />
      </div>
    </div>

    <!-- Diagnostic Issues -->
    <div class="card md diag card-diag" v-show="measuresToDisplay.indexOf(1) !== -1">
      <div class="card-top">
        <span class="title">{{ $t("message.diagnosticIssues") }}</span>
        <span class="dots">
          <img src="@/assets/more_horiz.svg?external" />
        </span>
      </div>
      <div class="vertical flex">
        <!-- <BasicMeasure
          :number="dataset.dashboard[mode].diagnosticIssues"
          :label="$t('message.vehicles')"
          diagnosticIcon
        /> -->
        <div class="issue-table">
          <b>{{ dataset.dashboard[mode].diagnosticIssues }}</b>
          <span><div class="dot" style="background-color: #ff4f72" />{{ $t('message.high') }}</span>
          <b>0</b>
          <span><div class="dot" style="background-color: #ffab40" />{{ $t('message.medium') }}</span>
          <b>{{ dataset.dashboard[mode].vehicleCount - dataset.dashboard[mode].diagnosticIssues }}</b>
          <span><div class="dot" style="background-color: #e6e8ed" />{{ $t('message.noIssues') }}</span>
        </div>
        <BarChart
          class="bar-chart"
          :value="(dataset.dashboard[mode].diagnosticIssues / dataset.dashboard[mode].vehicleCount * 100)"
          color="#ff4f72"
          secondaryColor="#ffab40"
          :width="400"
          :height="10"
          rounded
        />
      </div>
    </div>

    <!-- Top Vehicle Locations -->
    <div class="card md" v-show="measuresToDisplay.indexOf(18) !== -1">
      <div class="card-top">
        <span class="title">{{ $t('message.topVehicleLocations') }}</span>
        <span class="dots">
          <img src="@/assets/more_horiz.svg?external" />
        </span>
      </div>
      <div class="bar-graph-wrap">
        <BarGraph
          horizontal
          :values="topVehicleLocations.values"
          :xBarOffsets="topVehicleLocations.xBarOffsets"
          :xBarLabels="topVehicleLocations.xBarLabels"
          :xBarLabelOffsets="topVehicleLocations.xBarLabelOffsets"
          :xBarLabelsRotated="topVehicleLocations.xBarLabelsRotated"
          :yLabel="topVehicleLocations.yLabel"
          :yScale="topVehicleLocations.yScale"
          class="horizontal-bar-graph"
          :barWidth="30"
        >
        </BarGraph>
      </div>
    </div>

    <!-- Checked Out Vehicles -->
    <div class="card sm" v-show="measuresToDisplay.indexOf(0) !== -1">
      <div class="card-top">
        <span class="title">{{ $t("message.checkedOutVehicles") }}</span>
        <span class="dots">
          <img src="@/assets/more_horiz.svg?external" />
        </span>
      </div>
      <div class="vertical">
        <BasicMeasure
          :number="dataset.dashboard[mode].checkedOutVehicles"
          :minorNumber="dataset.dashboard[mode].vehicleCount"
          :label="$t('message.vehicles')"
          checkInIcon
        />
      </div>
    </div>

    <!-- Distance Driven -->
    <div class="card sm" v-show="measuresToDisplay.indexOf(3) !== -1">
      <div class="card-top">
        <span class="title">{{ $t("message.distanceDriven") }}</span>
        <span class="dots">
          <img src="@/assets/more_horiz.svg?external" />
        </span>
      </div>
      <MeasureDaysSelect
        v-model="distanceDays"
        style="margin-bottom: -25px"
        :activeChallenge="activeChallenge"
        @checkChallenge="checkChallenge($event)"
      />
      <div class="vertical">
        <BasicMeasure
          :number="
            parseInt(conversions.getMilesValue(
              dataset.dashboard[mode]['distanceDriven' + distanceDays + 'Day'],
              $i18n.locale
            ), 10)
          "
          :label="$i18n.locale !== 'en' ? $t('message.km') : $t('message.mi')"
          vehicleIcon
        />
      </div>
    </div>

    <!-- Fuel Consumption -->
    <div class="card sm" v-show="measuresToDisplay.indexOf(5) !== -1">
      <div class="card-top">
        <span class="title">{{ $t("message.fuelConsumption") }}</span>
        <span class="dots">
          <img src="@/assets/more_horiz.svg?external" />
        </span>
      </div>
      <MeasureDaysSelect v-model="fuelDays" style="margin-bottom: -25px" />
      <div class="vertical">
        <BasicMeasure
          :number="
            parseInt(conversions.getGalValue(
              dataset.dashboard[mode]['fuelConsumption' + fuelDays + 'Day'],
              $i18n.locale
            ), 10)
          "
          :label="$i18n.locale !== 'en' ? $t('message.l') : $t('message.gal')"
          fuelIcon
        />
      </div>
    </div>

    <!-- Engine Hours -->
    <div class="card md" v-show="measuresToDisplay.indexOf(12) !== -1">
      <div class="card-top">
        <span class="title">{{ $t('message.engineHours') }}</span>
        <span class="dots">
          <img src="@/assets/more_horiz.svg?external" />
        </span>
      </div>
      <div class="flex vertical" style="height: calc(100% - 32px); margin-top: 16px;">
        <div class="leaderboard-grid mileage" v-for="(vehicle, index) of topMileageVehicles" :key="'E' + index">
          <img :src="require('@/assets/' + vehicle['Image'] + '?external')" />
          <div class="flex column">
            <span style="font-size: 16px; font-weight: 600; line-height: 18px">{{ vehicle["Vehicle Label"] }}</span>
            <span style="font-size: 12px; color: var(--grey); line-height: 16px">{{ $store.state.inDealerMode ?
            `${vehicle["Make"]} ${vehicle["Model Name"]}` : vehicle["Model Name"] }}</span>
          </div>
          <div class="flex">
            <div style="
                    font-size: 21px;
                    font-weight: 800;
                    width: 65px;
                    text-align: right;
                    margin-right: 10px;
                  ">
              {{ odometerReading(vehicle).substr(0,2) }}
            </div>
            <div style="
                    display: flex;
                    align-items: flex-end;
                    line-height: 26px;
                  ">
              hr
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Fuel and Energy Level -->
    <div class="card md fuel-energy-level-card" v-show="measuresToDisplay.indexOf(11) !== -1">
      <div class="card-top">
        <span class="title">{{ $t('message.fuelAndEnergyLevel') }}</span>
        <span class="dots">
          <img src="@/assets/more_horiz.svg?external" />
        </span>
      </div>
      <div class="bar-graph-wrap">
        <BarGraph
          :values="fuelEnergyLevel.values"
          :xBarOffsets="fuelEnergyLevel.xBarOffsets"
          :xBarLabels="fuelEnergyLevel.xBarLabels"
          :xBarLabelsRotated="fuelEnergyLevel.xBarLabelsRotated"
          :yLabel="fuelEnergyLevel.yLabel"
          :yScale="fuelEnergyLevel.yScale"
          :barWidth="10.5"
        >
          <template v-slot:legend>
            <div class="legend">
              <div class="small-dot" style="background-color: #05B5E4;" />
              <span>{{ $t('message.fuel') }}</span>
              <div class="small-dot" style="background-color: #1CD8AA;" />
              <span>{{ $t('message.energy') }}</span>
            </div>
          </template>
        </BarGraph>
      </div>
    </div>

    <!-- Top Mileage -->
    <div class="card md" v-show="measuresToDisplay.indexOf(10) !== -1">
      <div class="card-top">
        <span class="title">{{ $t("message.topMileage") }}</span>
        <span class="dots">
          <img src="@/assets/more_horiz.svg?external" />
        </span>
      </div>
      <div class="flex vertical" style="height: calc(100% - 32px); margin-top: 16px;">
        <div
          class="leaderboard-grid mileage"
          v-for="(vehicle, index) of topMileageVehicles"
          :key="'E' + index"
        >
          <img
            :src="require('@/assets/' + vehicle['Image'] + '?external')"
          />
          <div class="flex column">
            <span
              style="font-size: 16px; font-weight: 600; line-height: 18px"
              >{{ vehicle["Vehicle Label"] }}</span
            >
            <span
              style="font-size: 12px; color: var(--grey); line-height: 16px"
              >{{ $store.state.inDealerMode ? `${vehicle["Make"]} ${vehicle["Model Name"]}` : vehicle["Model Name"] }}</span
            >
          </div>
          <div class="flex">
            <div
              style="
                font-size: 21px;
                font-weight: 800;
                width: 65px;
                text-align: right;
                margin-right: 12px;
              "
            >
              {{ odometerReading(vehicle) }}
            </div>
            <div
              style="
                display: flex;
                align-items: flex-end;
                line-height: 26px;
              "
            >
              {{ $t('message.mi') }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Low Oil Life -->
    <div
      class="card md"
      v-show="measuresToDisplay.indexOf(6) !== -1"
      ref="oil_change_complete"
      id="oil-change-complete"
    >
      <div class="card-top">
        <span class="title">{{ $t("message.lowOilLife") }}</span>
        <span class="dots">
          <img src="@/assets/more_horiz.svg?external" />
        </span>
      </div>
      <div class="vertical flex">
        <div
          class="flex"
          v-for="(vehicle, index) of lowOilLifeVehicles"
          :key="'G' + index"
          style="align-items: center"
        >
          <span
            :style="barWidth === 220 ? 'text-align: right; width: 120px' : ''"
            >{{ vehicle["Vehicle Label"] }}</span
          >
          <BarChart
            class="bar-chart"
            :value="vehicle.details.oilLife"
            :color="oilColor(vehicle.details.oilLife)"
            :width="barWidth"
            :height="3"
          />
          <b
            class="bar-chart-lbl"
            :style="{ color: oilColor(vehicle.details.oilLife) }"
            >{{ vehicle.details.oilLife }}%</b
          >
        </div>
      </div>
    </div>

    <!-- Days in Inventory -->
    <div class="card md" v-show="measuresToDisplay.indexOf(17) !== -1">
      <div class="card-top">
        <span class="title">{{ $t('message.daysInInventory') }}</span>
        <span class="dots">
          <img src="@/assets/more_horiz.svg?external" />
        </span>
      </div>
      <div class="bar-graph-wrap">
        <BarGraph
          dotted
          :values="daysInInventory.values"
          :xBarOffsets="daysInInventory.xBarOffsets"
          :xBarLabels="daysInInventory.xBarLabels"
          :xBarLabelOffsets="daysInInventory.xBarLabelOffsets"
          :xBarLabelsRotated="daysInInventory.xBarLabelsRotated"
          :barWidth="6"
          class="dotted-vertical-bar-graph"
        >
          <template v-slot:legend>
            <div class="legend">Days</div>
          </template>
        </BarGraph>
      </div>
    </div>

    <!-- Vehicles Condition -->
    <div class="card md" v-show="measuresToDisplay.indexOf(16) !== -1">
      <div class="card-top">
        <span class="title">{{ $t('message.vehiclesCondition') }}</span>
        <span class="dots">
          <img src="@/assets/more_horiz.svg?external" />
        </span>
      </div>
      <div class="bar-graph-wrap">
        <BarGraph
          :values="vehiclesCondition.values"
          :xBarOffsets="vehiclesCondition.xBarOffsets"
          :xBarLabels="vehiclesCondition.xBarLabels"
          :xBarLabelOffsets="vehiclesCondition.xBarLabelOffsets"
          :xBarLabelsRotated="vehiclesCondition.xBarLabelsRotated"
          :yLabel="vehiclesCondition.yLabel"
          :yScale="vehiclesCondition.yScale"
          class="vbg-with-vertical-scale"
          :width="100"
          :barWidth="10.5"
        >
          <template v-slot:legend>
            <div class="legend">
              <div class="small-dot" style="background-color: #1CD8AA;" />
              <span>{{ $t('message.inStock') }}</span>
              <div class="small-dot" style="background-color: #05B5E4;" />
              <span>{{ $t('message.inTransit') }}</span>
            </div>
          </template>
        </BarGraph>
      </div>
    </div>

    <!-- Energy Consumption / Expenses By Type / Expenses Over Time -->
    <div class="card md" v-for="(title, i) of energyAndExpensesMeasures" v-show="measuresToDisplay.indexOf(13 + i) !== -1" :key="i">
      <div class="card-top">
        <span class="title">{{ title }}</span>
        <span class="dots">
          <img src="@/assets/more_horiz.svg?external" />
        </span>
      </div>
      <MeasureDaysSelect :value="7" style="height: 26px" />
      <div class="flex column" style="height: 85%; align-items: center; justify-content: center;">
        <img style="width: 240px" src="@/assets/measures/no_data.svg?external" />
        <div style="margin-top: 16px; color: #76777a;">{{ $t('message.noDataAvailable') }}</div>
      </div>
    </div>

    <!-- Trends -->
    <div class="card xl" v-show="measuresToDisplay.indexOf(8) !== -1">
      <div class="card-top">
        <span class="title">{{ $t("message.trends") }}</span>
        <span class="dots">
          <img src="@/assets/more_horiz.svg?external" />
        </span>
      </div>
      <div
        class="flex"
        style="
          justify-content: space-between;
          align-items: center;
          margin-top: 16px;
        "
      >
        <MeasureDaysSelect v-model="trendDays" style="height: 26px" />
        <div class="flex center">
          <span>{{ $t("message.metric") }}</span>
          <div class="dropdown">
            <span class="dropdown-selected-option">{{
              $t("message.milesDriven")
            }}</span>
            <img
              class="dropdown-arrow"
              src="@/assets/arrow_down.svg?external"
            />
          </div>
        </div>
      </div>
      <!-- <div class="vertical flex">
        <img src="@/assets/widget.svg?external" />
        <span class="no-data">No Data Available</span>
      </div>-->
      <div class="graph-wrap" v-if="trendDays === 7">
        <span class="y"
          >{{ conversions.getMilesValue(87, $i18n.locale) + $t("message.mi") }}
        </span>
        <span class="y"
          >{{ conversions.getMilesValue(75, $i18n.locale) + $t("message.mi") }}
        </span>
        <span class="y"
          >{{ conversions.getMilesValue(62, $i18n.locale) + $t("message.mi") }}
        </span>
        <span class="y"
          >{{ conversions.getMilesValue(50, $i18n.locale) + $t("message.mi") }}
        </span>
        <span class="y"
          >{{ conversions.getMilesValue(37, $i18n.locale) + $t("message.mi") }}
        </span>
        <span class="y"
          >{{ conversions.getMilesValue(25, $i18n.locale) + $t("message.mi") }}
        </span>
        <span class="y"
          >{{ conversions.getMilesValue(12, $i18n.locale) + $t("message.mi") }}
        </span>
        <span />
        <span class="x first">{{ $t("message.sat") }}</span>
        <span class="x">{{ $t("message.mon") }}</span>
        <span class="x">{{ $t("message.wed") }}</span>
        <span class="x last">{{ $t("message.fri") }}</span>
        <div class="graph">
          <img src="@/assets/graph2.png" />
        </div>
      </div>
      <div class="graph-wrap" v-else>
        <span class="y"
          >{{ conversions.getMilesValue(186, $i18n.locale) + $t("message.mi") }}
        </span>
        <span />
        <span class="y"
          >{{ conversions.getMilesValue(124, $i18n.locale) + $t("message.mi") }}
        </span>
        <span />
        <span class="y"
          >{{ conversions.getMilesValue(62, $i18n.locale) + $t("message.mi") }}
        </span>
        <span />
        <span class="y">{{ "0" + $t("message.mi") }} </span>
        <span />
        <span class="x first" />
        <span class="x">{{ days[0] }}</span>
        <span class="x">{{ days[1] }}</span>
        <span class="x last">{{ days[2] }}</span>
        <div class="graph">
          <img src="@/assets/graph1.png" />
        </div>
      </div>
    </div>

    <slot name="grid-bottom" />
  </div>
</template>

<script>
import DonutChart from "@/components/demo/DonutChart.vue";
import BasicMeasure from "@/components/demo/BasicMeasure.vue";
import BarChart from "@/components/demo/BarChart.vue";
import moment from "moment";
import MeasureDaysSelect from "@/components/demo/MeasureDaysSelect.vue";
import * as conversions from "../../utils/conversions.js";
import { camelize } from "../../utils/camelize.js";
import BarGraph from "./BarGraph.vue";

// For dot vertical bar chart
const dotSizePercent = 380 / 63 / 100;

export default {
  components: {
    DonutChart,
    BasicMeasure,
    BarChart,
    MeasureDaysSelect,
    BarGraph
},
  data() {
    return {
      behaviorDays: 7,
      economyDays: 7,
      distanceDays: 7,
      fuelDays: 7,
      trendDays: 7,
      bingo: false,
      startTime: Number,
      conversions: conversions,
      camelize: camelize,
      fuelEnergyLevel: {
        values: {
          '#1CD8AA': [0, 0, 0, 0, 0, 0, 0, 0, (15/15), 0, 0],
          '#05B5E4': [0, 0, 0, 0, 0, 0, 0, 0, (14/15), 0, 0],
        },
        xBarLabels: ['0%-10%', '11%-20%', '21%-30%', '31%-40%', '41%-50%', '51%-60%', '61%-70%', '71%-80%', '81%-90%', '91%-100%', this.$t('message.unknown')],
        xBarOffsets: [17.5, 52.5, 87.5, 122.5, 157.5, 192.5, 227.5, 262.5, 297.5, 332.5, 367.5],
        xBarLabelsRotated: true,
        yLabel: this.$t('message.vehicles'),
        yScale: ['0', '', this.$store.state.inDealerMode ? '30' : '15'],
      },
      vehiclesCondition: {
        values: {
          '#1CD8AA': [(15/15)],
          '#05B5E4': [(2/15)],
        },
        xBarLabels: [this.$t('message.new')],
        xBarOffsets: [50],
        xBarLabelOffsets: [parseInt(this.$t('newOffset'), 10)],
        xBarLabelsRotated: false,
        yLabel: '',
        yScale: ['0', '', '30'],
      },
      topVehicleLocations: {
        values: {
          '#FFA840': [(15 / 15), 0, 0],
        },
        xBarLabels: ['0', '10', '20'],
        xBarOffsets: [75, 150, 300],
        xBarLabelOffsets: [-60, 30, 45],
        xBarLabelsRotated: false,
        yLabel: '',
        yScale: ['', this.$t('message.mainLot'), ''],
      },
      daysInInventory: {
        values: {
          '#C6C6CA': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          '#1CD8AA': [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          '#05B5E4': [(11 * dotSizePercent), (7 * dotSizePercent), 0, (6 * dotSizePercent), 0, (3 * dotSizePercent), (1 * dotSizePercent), 0, (1 * dotSizePercent), (1 * dotSizePercent), 0],
        },
        xBarLabels: ['0', '', '200', '', '400', '', '600', '', '800', '', '1000'],
        xBarOffsets: [17.5, 52.5, 87.5, 122.5, 157.5, 192.5, 227.5, 262.5, 297.5, 332.5, 367.5],
        xBarLabelOffsets: [8, 11, 13, 11, 12, 11, 11, 11, 11, 11, 11],
        xBarLabelsRotated: false,
      },
      energyAndExpensesMeasures: [
        this.$t('message.energyConsumption'),
        this.$t('message.expensesByType'),
        this.$t('message.expensesOverTime'),
      ],
    };
  },
  props: {
    dataset: Object,
    measuresToDisplay: Array[Number],
    activeChallenge: String,
    challenges: Array,
    timeLeft: Number,
    numIncomplete: Number,
    multiplier: Number,
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      const topOfTheElement =
        this.$refs.oil_change_complete.getBoundingClientRect().top;

      // check if element is in within visible area of viewport
      if (topOfTheElement >= 80 && topOfTheElement <= window.innerHeight) {
        // trigger checkChallenge after 1.5 seconds
        //otherwise, it's so fast you can't see the low oil section
        setTimeout(this.checkChallenge, 750);
      }
    },
    checkChallenge() {
      //checks if go the extra mile/need ann oil change challenges are completed and alerts parent components
      if (this.activeChallenge === "go-the-extra-mile") {
        this.$emit("challengeComplete", 21);
        this.$emit("pauseTimer");
        this.bingo = true;
        this.$emit("bingo");
      }
      if (this.activeChallenge === "need-an-oil-change") {
        this.$emit("challengeComplete", 22);
        this.$emit("pauseTimer");
        this.bingo = true;
        this.$emit("bingo");
      }
    },
    scoreColor(score) {
      if (score > 89) return "var(--green)";
      else if (score < 80) return "var(--red)";
      return "var(--orange)";
    },
    oilColor(score) {
      if (score > 30) return "var(--green)";
      else if (score < 5) return "var(--red)";
      else if (score < 20) return "var(--yellow)";
      return "var(--yellow)";
    },
    odometerReading(vehicle) {
      if (this.$store.state.inDealerMode) {
        return vehicle["Odometer (mi)"].toLocaleString(this.localeStr);
      }
      return vehicle.details.odometer.toLocaleString(this.localeStr);
    }
  },
  challengeComplete(chal) {
    this.$emit("challengeComplete", chal);
  },
  computed: {
    sortedDrivers() {
      let drivers = [];
      drivers = JSON.parse(JSON.stringify(this.dataset.drivers));
      drivers.sort((a, b) => {
        return (
          a.details.score[this.behaviorDays + "Day"] -
          b.details.score[this.behaviorDays + "Day"]
        );
      });
      return drivers;
    },
    reverseSortedDrivers() {
      return this.sortedDrivers.slice().reverse();
    },
    topVehicles() {
      let arr = [];
      arr.push(this.dataset.vehicles[1]);
      arr.push(this.dataset.vehicles[2]);
      return arr;
    },
    bottomVehicles() {
      let arr = [];
      arr.push(this.dataset.vehicles[0]);
      arr.push(this.dataset.vehicles[4]);
      return arr;
    },
    lowOilLifeVehicles() {
      if (this.$store.state.inDealerMode) {
        const dealerModeVehicles = JSON.parse(JSON.stringify(this.dataset.dealerModeVehicles));
        dealerModeVehicles.sort((a, b) => a.details.oilLife - b.details.oilLife);
        return dealerModeVehicles.slice(0, 5);
      }
      let arr = [];
      arr.push(this.dataset.vehicles[0]);
      arr.push(this.dataset.vehicles[2]);
      arr.push(this.dataset.vehicles[1]);
      arr.push(this.dataset.vehicles[4]);
      return arr;
    },
    topMileageVehicles() {
      if (this.$store.state.inDealerMode) {
        const dealerModeVehicles = JSON.parse(JSON.stringify(this.dataset.dealerModeVehicles));
        dealerModeVehicles.sort((a, b) => b["Odometer (mi)"] - a["Odometer (mi)"]);
        return dealerModeVehicles.slice(0, 5);
      }
      let arr = [];
      arr.push(this.dataset.vehicles[2]);
      arr.push(this.dataset.vehicles[1]);
      arr.push(this.dataset.vehicles[4]);
      arr.push(this.dataset.vehicles[3]);
      arr.push(this.dataset.vehicles[0]);
      return arr;
    },
    days() {
      let arr = [];
      arr.push(moment().subtract(14, "days").day(-1).format("MMM D"));
      arr.push(moment().subtract(7, "days").day(-1).format("MMM D"));
      arr.push(moment().format("MMM D"));
      return arr;
    },
    barWidth() {
      if (this.$parent.$parent.displayWidth <= 460) return 172;
      return 220;
    },
    localeStr() {
      switch (this.$i18n.locale) {
        case 'en':
          return 'en-US';
        case 'es':
          return 'es-MX';
        default:
          return this.$i18n.locale;
      }
    },
    mode() {
      if (this.$store.state.inDealerMode) return 'dealer';
      return 'fleet';
    }
  },
  created() {
    this.startTime = this.timeLeft;
  },
  watch: {
    behaviorDays: function () {
      if (
        this.behaviorDays === 30 &&
        this.activeChallenge === "whos-your-top-driver"
      ) {
        this.bingo = true;
        this.$emit("challengeComplete", 14);
        this.$emit("pauseTimer");
      }
    },
    numIncomplete: function () {
      if (this.numIncomplete === 0) {
        this.$emit("noMoreChallenges");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-area {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 222px);
  justify-content: center;
  grid-gap: 10px;
  /* min-width: 686px; */
  width: 95%;
  grid-auto-flow: dense;
}
.card {
  border: 1px solid var(--light-grey-2);
  padding: 16px 24px;
  min-height: 280px;
  .is-essentials & {
    filter: blur(5px);
  }
  &.card-vehicles-snapshot, &.card-diag {
    filter: blur(0);
  }
}
.card h1 {
  margin: 0;
  margin-top: 40px;
}
.card.md {
  grid-column: span 2;
}
.card.lg {
  grid-column: span 3;
  min-height: 320px;
}

.card.xl {
  height: 400px;
  grid-column: span 3;
}
.card.xl .vertical.flex {
  height: 362px;
}
.no-data {
  margin-top: 10px;
}
.card-top {
  display: flex;
  justify-content: space-between;
}
.card-top-subtitle {
  width: fit-content;
  margin-top: 6px;
  /* margin-bottom: -26px; */
  color: var(--blue);
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
}
.card-top-subtitle.selected {
  border-bottom: 1px solid var(--blue);
}
.title,
.dots {
  font-family: ProximaNova;
  font-weight: 700;
  color: var(--dark);
}
.title {
  text-transform: uppercase;
}
.dots {
  line-height: 16px;
  display: flex;
  align-items: center;
}
.dots img {
  width: 13px;
  height: 13px;
}
.vertical {
  height: 257px;
}
.vertical.flex {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wide-stat {
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}
.body {
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.wide-stat-divider {
  margin: 0 18px;
  width: 1px;
  border-left: solid 0.5px var(--light-grey-2);
  /* height: 179px; */
  margin-top: 50px;
  /* background-color: var(--light-grey-2); */
}
.bar-chart {
  margin-left: 20px;
}
.bar-chart-lbl {
  width: 30px;
  margin-left: 20px;
  color: var(--green);
}
.leaderboard-grid {
  display: grid;
  grid-template-columns: 36px auto 32px;
  grid-gap: 0 8px;
  margin: 6px 4px;
  align-items: center;
}
.leaderboard-grid.fuel-economy {
  grid-template-columns: 80px auto 75px;
}
.leaderboard-grid.mileage {
  grid-template-columns: 80px auto 95px;
  width: 100%;
}
.leaderboard-grid.fuel-economy img,
.leaderboard-grid.mileage img {
  width: 120%;
  justify-self: center;
}
.circle {
  background-color: #ee544f;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 14px;
  line-height: unset;
  font-weight: 700;
}
.purple {
  background-color: #6f166d;
}
.graph-wrap {
  width: 580px;
  margin-left: 28px;
  margin-top: 45px;
  display: grid;
  grid-template-columns: 50px 165px 178px 130px auto;
  grid-template-rows: repeat(6, 35px) 18px 35px;
  grid-auto-flow: column;
  transform: scale(1.05);
}
.x,
.y {
  font-size: 13px;
  color: var(--grey);
}
.x.first {
  margin-left: -8px;
}
.x.last {
  justify-self: end;
}
.graph-wrap .y {
  align-self: flex-start;
  justify-self: center;
}
.graph {
  grid-column: 2 / 6;
  grid-row: 1 / 8;
  border-left: 2px solid var(--light-grey-2);
  border-bottom: 2px solid var(--light-grey-2);
}
.graph img {
  width: 100%;
}
.dropdown {
  width: 200px;
  height: 30px;
  margin-left: 8px;
  background-color: var(--white);
  font-family: ProximaNova;
  padding-left: 12px;
  border: 1px solid var(--light-grey-2);
  position: relative;
  z-index: 1;
  transition: transform 0.5s ease;
  cursor: pointer;
}
.dropdown-selected-option {
  position: absolute;
  top: 3px;
  left: 12px;
  transition: transform 0.5s ease;
}
.dropdown-arrow {
  position: absolute;
  top: 3px;
  right: 3px;
  transition: transform 0.5s ease;
}
/*
large card mobile/tablet
*/
@media screen and (max-width: 980px) {
  .dashboard-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
  .card {
    padding: 0 5px;
    display: flex;
    flex-direction: column;
    width: 90%;
  }
  h1,
  h3 {
    margin: 0;
  }
  .card.lg {
    display: flex;
    flex-direction: column;
    height: fit-content;
  }
  .card.md {
    display: flex;
  }
  .card-top {
    width: 100%;
  }
  .title {
    width: 90%;
  }

  .body.vertical {
    height: auto;
  }

  .wide-stat-divider {
    display: none;
  }
  .flex {
    flex-wrap: wrap;
  }
  .leaderboard-grid {
    width: 100%;
  }
  .card.xl {
    display: flex;
    border: 1px solid blue;
    align-items: center;
    flex-direction: column;
  }
  .graph-wrap {
    width: 130%;
    /* min-width: 380px; */
    margin-left: 0px;
    margin-top: 0px;
    display: grid;
    grid-template-columns: 50px 25% 25% auto;
    grid-template-rows: repeat(6, 35px) 18px 35px;
    grid-auto-flow: column;
    transform: scale(0.7);
  }
}
.diag .bar-chart {
  margin: 0;
}
.snapshot {
  display: flex;
  flex-direction: column;
}
.issue-table {
  width: 100%;
  max-width: 400px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 24px 24px;
  gap: 8px 0;
  margin-bottom: 12px;
}
.issue-table b {
  font-size: 19px;
}
.issue-table span {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #606164;
}
.issue-table .dot {
  width: 11px;
  height: 11px;
  border-radius: 50%;
}
.bar-graph-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  overflow-x: scroll;
}
.legend {
  width: 100%;
  grid-row: 3 / 4;
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 8px auto 8px auto;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.legend span {
  white-space: nowrap;
}
.vbg-with-vertical-scale {
  display: grid;
  grid-template-columns: 58px 100px 100px;
  grid-template-rows: 150px 20px;
  /* width: 158px; */
  overflow: hidden;
}
.vbg-with-vertical-scale .legend {
  width: 100%;
  height: 46px;
  grid-row: 1 / -1;
  grid-column: 3 / 4;
  display: grid;
  grid-template-columns: 8px 36px;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.small-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.dotted-vertical-bar-graph {
  grid-template-rows: 150px 30px 48px;
  margin-left: 9px;
}
.dotted-vertical-bar-graph .legend {
  font-size: 13px;
  display: block;
  text-align: center;
  color: #171c26;
}
.horizontal-bar-graph {
  display: grid;
  grid-template-columns: 58px auto;
  grid-template-rows: 150px 20px;
  /* width: 158px; */
  overflow: hidden;
}

.is-essentials .fuel-energy-level-card {
  /* grid-column: 1 / -1; */
}
</style>
