<template>
  <div class="performance">
    <h1 v-if="!isMobile">{{$t('message.performance')}}</h1>
    <div v-else style="margin-top: 20px;" />
    <ModalView v-show="showCustomizeModal" @hide="showCustomizeModal = false">
      <CustomizeMeasures
        @hide="showCustomizeModal = false"
        @toggleMeasure="handleToggleMeasure"
        :measuresToDisplay="measuresToDisplay"
        :availableMeasures="availableMeasures"
      />
    </ModalView>
    <div class="top-bar">
      <!-- <span v-if="!$store.state.inDealerMode" style="margin-bottom: 24px;">{{$t('message.fleet')}}</span> -->
      <span v-if="!$store.state.inDealerMode">{{ $t('message.fleet') }}</span>
      <DropDown
        v-if="!$store.state.inDealerMode"
        :options="options.map((o) => o.text)"
        :value="selectedFleet.text"
        @input="selectedFleet = options.find((o) => o.text === $event)"
        class="dropdown"
      />
      <div
        class="customize-btn"
        v-if="measuresToDisplay.length"
        @click="showCustomizeModal = true"
      >
        <svg
          id="gfs:icon1/edit"
          width="18px"
          height="18px"
          viewBox="0 0 24 24"
        >
          <path
            fill="var(--blue)"
            d="M16.995 3L5.007 14.997 3 21l6.003-1.998L21 7.005 16.995 3zM5.241 18.759l.9-2.637 1.8 1.8-2.7.837zm3.762-1.8l-1.998-1.962 7.992-7.992 1.998 1.998-7.992 7.956zm6.993-10.953l.999-.999 1.998 1.998-.999.999-1.998-1.998z"
          />
        </svg>
        <span>{{$t('message.measureSettings')}}</span>
      </div>
    </div>

    <div class="gradient-divider" />

    <DashboardArea
      class="dashboard-measures"
      :dataset="dataset"
      :measuresToDisplay="measuresToDisplay"
      :activeChallenge="activeChallenge"
      :challenges="challenges"
      :timeLeft="timeLeft"
      :numIncomplete="numIncomplete"
      @challengeComplete="challengeComplete($event)"
      @pauseTimer="pauseTimer()"
      @noMoreChallenges="noMoreChallenges()"
      v-if="measuresToDisplay.length"
    />

    <div class="no-measures flex column center" v-else>
      <img src="@/assets/dashboard-empty.svg?external" />
      <span>Your dashboard is empty</span>
      <div @click="showCustomizeModal = true" class="action-button major">
        Add Measures
      </div>
    </div>

    <GuidedTourWaypoint :id="21" target="gfs:icon1/edit" :xOffset="18" :yOffset="26" />
  </div>
</template>

<script>
import DashboardArea from '@/components/demo/DashboardArea.vue';
import ModalView from '@/components/demo/ModalView.vue';
import CustomizeMeasures from '@/components/demo/CustomizeMeasures.vue';
import DropDown from '../../components/demo/DropDown.vue';
import GuidedTourWaypoint from '../../components/demo/GuidedTourWaypoint.vue';

export default {
  components: { DashboardArea, ModalView, CustomizeMeasures, DropDown, GuidedTourWaypoint },
  data() {
    return {
      lots: { 1: 'Main Lot', 2: 'Remote Lot', 3: 'Courtesy Lot' },
      availableMeasures: [],
      measuresToDisplay: [],
      fleetMeasures: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      dealerMeasures: [1, 2, 6, 9, 10, 11, 16, 17, 18],
      essentialsMeasures: [5, 6, 11, 12],
      showCustomizeModal: false,
      fleetDropdownOpen: false,
    };
  },
  methods: {
    handleToggleMeasure(number) {
      const index = this.measuresToDisplay.indexOf(number);
      if (index !== -1) {
        // toggle off
        this.measuresToDisplay.splice(index, 1);
      } else {
        // toggle on
        this.measuresToDisplay.push(number);
      }
    },
    challengeComplete(chal) {
      this.$emit('challengeComplete', chal);
    },
    pauseTimer() {
      this.$emit('pauseTimer');
    },
    noMoreChallenges() {
      this.$emit('noMoreChallenges');
    },
  },
  mounted() {
    this.$route.params.title = 'Performance';
    // if (this.activeChallenge === 'monitor-what-matters') {
    //   this.measuresToDisplay = [];
    // }
    if (this.$store.state.inDealerMode) {
      this.availableMeasures = [...this.dealerMeasures];
    } else if (this.$store.state.inEssentialsMode) {
      this.availableMeasures = [...this.essentialsMeasures];
    } else {
      this.availableMeasures = [...this.fleetMeasures];
    }
    this.measuresToDisplay = [...this.availableMeasures];
  },
  props: {
    dataset: Object,
    isMobile: Boolean,
    challenges: Array,
    activeChallenge: String,
    numIncomplete: Number,
    timeLeft: Number,
  },
  computed: {
    options() {
      return this.$store.inDealerMode ? this.lots : this.$store.state.fleets;
    },
    selectedFleet: {
      get() {
        return this.$store.state.selectedFleet;
      },
      set(value) {
        this.$store.commit('setSelectedFleet', value);
      },
    },
  },
};
</script>

<style scoped>
.performance {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dashboard-measures {
  margin-bottom: 32px;
}
.customize-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 15px;
  line-height: 13px;
  font-weight: 600;
  width: 100%;
  color: var(--blue);
  /* margin-top: 6px;
  margin-bottom: 28px; */
}
.customize-btn svg {
  padding-right: 7.5px;
}
.customize-btn svg,
.customize-btn span {
  cursor: pointer;
}
.no-measures {
  padding-top: 120px;
}
.no-measures img {
  margin-bottom: 12px;
}
.no-measures .action-button.major {
  width: 133px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  border: 1px solid var(--blue);
  background-color: var(--blue);
  color: var(--white);
  margin-top: 36px;
}
.top-bar {
  width: 80%;
  height: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.dropdown {
  margin-left: 12px;
  white-space: nowrap;
}

@media screen and (max-width: 980px) {
  .gradient-divider {
    height: 25px;
    min-height: 25px;
  }
}
</style>
