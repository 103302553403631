<template>
  <div class="top-bar-wrap">
    <div class="flex topbar">
      <div class="flex start">
        <svg
          v-if="!showSidebar && (!canGoBack || !isMobile)"
          class="collapse-btn"
          @click="$emit('toggle-sidebar')"
          id="gml:icon/shell-menu"
          viewBox="0 0 24 24"
        >
          <path
            fill="var(--dark-grey)"
            d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z"
          />
        </svg>

        <img
          v-if="!showSidebar && !canGoBack && !isMobile"
          class="logo"
          @click="reset()"
          src="@/assets/logo-twolines-left-blue.svg?external"
        />
        <svg
          class="collapse-btn"
          v-if="canGoBack && isMobile"
          @click="goBack"
          id="gml:icon1/shell-back"
          viewBox="0 0 24 24"
        >
          <path
            fill="var(--dark-grey)"
            d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
          ></path>
        </svg>
        <div
          class="item back"
          v-else-if="canGoBack"
          @click="goBack"
          :style="!showSidebar ? 'border-left: 1px solid var(--pale-grey)' : ''"
        >
          <svg id="gml:icon/shell-breadcrumb" viewBox="0 0 24 24">
            <path
              fill="var(--dark-grey)"
              d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
            />
          </svg>
          {{ $t("message." + camelize(pageToGoBackTo)) }}
        </div>
      </div>
      <div class="flex middle" v-if="showTitle || isMobile">
        <h2 v-if="$route.name === 'vehicle-detail'">{{ labelToDisplay }}</h2>
        <h2 v-else-if="$route.name === 'set-notification'">
          {{ $route.params.editing ? $t("message.edit") : $t("message.new") }}
          {{ labelToDisplay }}
          {{ labelToDisplay === 'Geofence' ? '' : $t("message.notification") }}
        </h2>
        <h2 v-else>{{ $t("message." + camelize(labelToDisplay)) }}</h2>
      </div>
      <div class="flex end">
        <div style="flex-shrink: 0;" v-if="isLocalEnv && !isMobile">Locale (local only):&nbsp;</div>
        <select v-if="isLocalEnv && !isMobile" name="locales" v-model="currentLocale" >
          <option value="en">en</option>
          <option value="en-ca">en-ca</option>
          <option value="fr-ca">fr-ca</option>
          <option value="es">es</option>
        </select>
        <div class="notifications">
          <!-- <p v-if="!$store.state.inDealerMode" class="item">
            {{ $t("message.notifications") }}
          </p> -->
          <svg
            class="item bell"
            :class="{ mobile: showTitle || isMobile }"
            id="gfs:icon/i-notification"
            viewBox="0 0 24 24"
            @click="toggleNotificationsCenter"
          >
            <path
              fill="#76777a"
              d="M20.958 17.058a2.3 2.3 0 0 0-1.016-1.908l-1.017-.65a1.033 1.033 0 0 1-.467-.833v-2.934a5.642 5.642 0 0 0-2.833-4.883l-2.5-1.417a1.033 1.033 0 0 1-.525-.9v-.7h-1.25v.7c0 .373-.2.717-.525.9l-2.5 1.417a5.642 5.642 0 0 0-2.833 4.883v2.875c-.01.338-.185.649-.467.834l-1.017.675a2.3 2.3 0 0 0-1.016 1.908v1.4h5.491a3.525 3.525 0 0 0 7.034 0h5.441v-1.367zM12 20.542a2.275 2.275 0 0 1-2.267-2.084h4.534A2.275 2.275 0 0 1 12 20.542zm7.708-3.334H4.292v-.15c.01-.337.184-.648.466-.833l1.017-.675a2.3 2.3 0 0 0 1.017-1.908v-2.909A4.383 4.383 0 0 1 9 6.933l2.5-1.408c.197-.113.376-.253.533-.417.158.164.337.304.534.417l2.5 1.408a4.383 4.383 0 0 1 2.208 3.8v2.875a2.3 2.3 0 0 0 1.017 1.909l1.016.675c.283.185.457.496.467.833l-.067.183z"
            />
          </svg>
          <GuidedTourWaypoint v-if="$route.name === 'home'" :id="1" :xOffset="16" :yOffset="6" target="gfs:icon/i-notification" :zIndex="1000" />
        </div>
        <!-- <div
          v-else
          :style="$store.state.inDealerMode ? 'width: 96px;' : 'width: 76px;'"
        /> -->
        <FleetsDropdown
          v-show="!showTitle && !isMobile && !$store.state.inDealerMode"
        />
        <div v-if="!isMobile" style="width: 135px; text-align: center; line-height: 15px;">
          {{ $t('message.accountAdmin') }}
        </div>
        <!-- <div v-if="$store.state.inDealerMode" :style="{ display: 'flex' }">
          <span
            :style="{
              fontSize: '12px',
              minWidth: 'fit-content',
              lineHeight: '20px',
            }"
            >{{ $t('message.fleetManager') }}</span
          >
          <DownArrowIcon
            :style="{
              position: 'relative',
              width: '20px',
              color: 'var(--dark-grey)',
              marginTop: '1px',
            }"
          />
        </div> -->
        <svg
          class="item profile"
          v-show="!isMobile"
          id="gfs:icon/i-session-lg"
          viewBox="0 0 24 24"
          @click="showProfileMenu = true"
        >
          <g transform="scale(.6)">
            <path
              fill="#76777a"
              d="M23.6 31.91l-.92-2.32-1.57.63 1 2.51a14.15 14.15 0 0 1-12.23 0l1-2.51-1.57-.63-.91 2.32a14.5 14.5 0 0 1-3.05-2.57l1.31-2.44a4.14 4.14 0 0 1 3.65-2h1.08L16 30.38l4.61-5.53h1.08c1.53-.02 2.95.8 3.68 2.15l1.28 2.37c-.88.99-1.89 1.85-3 2.57l-.05-.03zm-5.18-7.06L16 27.75l-2.41-2.9h4.83zm-6.63-6.92v-1.31a4.33 4.33 0 0 1 4.21-4.4 4.33 4.33 0 0 1 4.21 4.4v1.31a4.34 4.34 0 0 1-4.21 4.4 4.34 4.34 0 0 1-4.21-4.4zM16 5.48c7.86 0 14.32 6.46 14.32 14.31 0 2.9-.89 5.73-2.53 8.12l-.93-1.74a5.82 5.82 0 0 0-5.17-3H19a6.13 6.13 0 0 0 2.91-5.24v-1.31A6.03 6.03 0 0 0 16 10.54a6.03 6.03 0 0 0-5.89 6.08v1.31A6.13 6.13 0 0 0 13 23.17h-2.69a5.82 5.82 0 0 0-5.12 2.91l-1 1.82a14.2 14.2 0 0 1-2.53-8.1A14.4 14.4 0 0 1 16 5.48zm0-1.68c-8.78 0-16 7.22-16 16 0 8.78 7.22 16 16 16 8.78 0 16-7.22 16-16 0-8.78-7.22-16-16-16z"
            />
            <circle cx="32" cy="27.8" r="8" fill="#76777a" />
            <circle cx="32" cy="27.8" r="6.5" fill="#fff" />
            <path
              fill="var(--blue)"
              d="M28.63 25.55h6.75c.18.03.32.19.32.38s-.14.34-.32.37h-6.81a.38.38 0 0 1-.38-.38.38.38 0 0 1 .44-.37zm0 2.25h6.75c.18.03.32.19.32.38s-.14.34-.32.37h-6.81a.38.38 0 0 1-.38-.38.38.38 0 0 1 .44-.37zm0 2.25h6.75c.18.03.32.19.32.38s-.14.34-.32.37h-6.81a.38.38 0 0 1-.38-.38.38.38 0 0 1 .44-.37z"
            />
          </g>
        </svg>
      </div>
    </div>
    <!-- <div
      v-if="showNotifications"
      v-on-click-outside="hideNotificationsOnClickOutside"
      class="notification-area"
    >
      <div class="noti-title-area">
        <h4>{{ $t("message.notifications") }}</h4>
        <span @click="goToNotificationSettings">{{
          $t("message.settings")
        }}</span>
      </div>
      <div class="long line" />
      <NotificationsList class="notifications-list" :data="notifications.slice(0, 4)" />
      <div class="view-all">
        <span @click="goToAllNotifications">{{ $t("message.viewAll") }}</span>
      </div>
    </div> -->
    <ProfileMenu v-if="showProfileMenu" @done="showProfileMenu = false" />
  </div>
</template>

<script>
import moment from "moment";
import FleetsDropdown from "./FleetsDropdown.vue";
// import NotificationsList from "./NotificationsList.vue";
// import DownArrowIcon from "@/assets/arrow_down.svg";
import { camelize } from "../../utils/camelize.js";
import ProfileMenu from "./ProfileMenu.vue";
import GuidedTourWaypoint from "./GuidedTourWaypoint.vue";

export default {
  components: {
    FleetsDropdown,
    ProfileMenu,
    GuidedTourWaypoint
},
  props: {
    showSidebar: Boolean,
    notifications: Array[Object],
    isMobile: Boolean,
  },
  data() {
    return {
      showTitle: false,
      showNotifications: false,
      canToggleNotifications: true,
      showProfileMenu: false,
      camelize: camelize,
    };
  },
  computed: {
    mobileNotiSettings() {
      if (
        this.$route.name === "notification-settings" &&
        this.isMobile &&
        this.$store.state.notiSettingsDetails
      ) {
        return true;
      }
      return false;
    },
    canGoBack() {
      if (this.mobileNotiSettings) return true;
      if (this.$route.name === "all-notifications" && this.isMobile)
        return true;
      if (this.$route.path.includes("detail")) return true;
      if (this.$route.path.includes("set")) return true;
      return false;
    },
    pageToGoBackTo() {
      if (this.canGoBack) {
        const pageNum = parseInt(this.$route.path.charAt(1));
        switch (pageNum) {
          case 1:
            return "Vehicles";
          case 2:
            return "Users";
          case 3:
            return "Managers";
          case 7:
            return "Settings";
          case 8:
            return "Add-on Solutions";
        }
      }
      return "";
    },
    labelToDisplay() {
      if (this.$route.name === "drivers-screen") {
        return "Users";
      }
      if (this.$route.params.title) return this.$route.params.title;
      if (this.$route.meta.label) return this.$route.meta.label;
      if (this.$route.name === "overview-screen") return this.fleet;
      return "";
    },
    generatedTimes() {
      let arr = [];
      for (let i = 0; i < this.notifications.length; i++) {
        const time = moment();
        time.subtract(30 * (i + 1), "minutes");
        const timeStr = time.format("MMM D, hh:mm A");
        arr.push(timeStr);
      }
      return arr;
    },
    currentLocale: {
      get() {
        return this.$store.state.locale;
      },
      set(val) {
        this.$store.commit('changeLocale', val);
        this.$i18n.locale = val;
        localStorage.setItem('locale', val);
      },
    },
    isLocalEnv() {
      return process.env.VUE_APP_LOCAL;
    },
  },
  methods: {
    goBack() {
      if (this.mobileNotiSettings) {
        this.$store.commit("setNotiSettingsDetails", false);
      } else if (this.$route.name === "all-notifications") {
        this.$router.go(-1);
      } else {
        this.$router.push("/" + this.$route.path.charAt(1));
      }
    },
    handleScroll() {
      if (window.scrollY >= 60 && this.$route.params.title)
        this.showTitle = true;
      else this.showTitle = false;
    },
    toggleNotificationsCenter() {
      if (this.isMobile) {
        this.goToAllNotifications();
      } else {
        this.$emit('toggle-notifications');
      }
    },
    // goToNotificationSettings() {
    //   if (this.$route.name !== "notification-settings") {
    //     this.$router.push({ name: "notification-settings" });
    //   }
    //   this.showNotifications = false;
    // },
    goToAllNotifications() {
      if (this.$route.name !== "all-notifications") {
        this.$router.push({ name: "all-notifications" });
      }
      // this.$emit('hide-notifications');
    },
    // hideNotificationsOnClickOutside() {
    //   if (this.canToggleNotifications) this.showNotifications = false;
    // },
    reset() {
      this.$parent.$emit("resetexp");
    },
  },
  watch: {
    showNotifications() {
      this.canToggleNotifications = false;
      setTimeout(() => {
        this.canToggleNotifications = true;
      }, 100);
    },
  },
  created() {
    document.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    document.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.top-bar-wrap {
  position: sticky;
  top: 0;
  z-index: 20;
  touch-action: none;
}
.topbar {
  width: 100%;
  height: 35px;
  border-bottom: 1px solid var(--light-grey-2);
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  padding: 10px 0;
}
.flex.start {
  min-width: 85px;
}
.collapse-btn {
  width: 20px;
  height: 20px;
  margin: 2px;
  padding: 16px;
  cursor: pointer;
}
.logo {
  height: 36px;
  padding: 10px;
  padding-left: 0;
  cursor: pointer;
}
.back {
  display: flex;
  align-items: center;
  justify-content: center;
  /* line-height: 12px; */
}
.back svg {
  width: 18px;
  height: 18px;
  margin-left: 2px;
  margin-right: 7px;
}
h2 {
  text-align: center;
}
.flex.end {
  height: 100%;
  align-items: center;
  color: #76777a;
}
.notifications {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.notifications p {
  padding-right: 0;
  cursor: default;
}
@media (max-width: 1000px) {
  .notifications p {
    display: none;
  }
}
.item {
  padding: 17px;
  cursor: pointer;
  color: var(--dark-grey);
}
.bell {
  width: 20px;
  height: 20px;
}
.bell.mobile {
  padding: 18px 28px;
}
.profile {
  width: 40px;
  height: 40px;
  padding: 0 18px;
}
.notification-area {
  position: absolute;
  top: 54px;
  right: 224px;
  width: 525px;
  height: 435px;
  background-color: var(--white);
  z-index: 1000;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  overflow-y: scroll;
}
.noti-title-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.noti-title-area span {
  font-weight: 600;
  color: var(--blue);
  margin-right: 16px;
  cursor: pointer;
}
.line {
  grid-column: 2 / 4;
  width: 100%;
  height: 1px;
  background-color: var(--light-grey);
  margin-top: 10px;
}
.long.line {
  width: 95%;
  margin: 0 auto 8px auto;
}
.view-all {
  font-size: 16px;
  color: var(--blue);
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 38px;
}
.view-all span {
  cursor: pointer;
}
.notifications-list {
  width: 95%;
  margin: 0 auto;
}
</style>
