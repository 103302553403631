<template>
  <div
    class="breadcrumb-popup map-popup flex column"
    :class="`type${dot.type}`"
    ref="popup"
  >
    <div class="close-icon-container icon flex" v-if="!showVideoPlayer">
      <CloseIcon @click="$emit('close')" style="cursor: pointer" />
    </div>
    <TripHistoryVideoPlayer
      v-if="showVideoPlayer"
      :src="'trip-history-videos/' + dot.data.video"
      @close="$emit('close')"
    />
    <div
      class="breadcrumb-popup-heading flex"
      :class="
        dot.data &&
        (dot.data.odometer || dot.data.speed || dot.data.video) &&
        !showVideoPlayer
          ? 'has-border'
          : ''
      "
    >
      <div class="breadcrumb-popup-heading-icon">
        <BaseIcon
          name="hardBrake"
          v-if="dot.type === 1"
          class="hard-brake-svg"
        />
        <BaseIcon
          name="hardAcceleration"
          v-else-if="dot.type === 2"
          class="hard-accel-svg"
        />
        <img
          src="@/assets/Video_71_.png"
          v-else-if="dot.type === 6"
          class="camera-svg"
        />
        <!-- <HardBrakingSVG  /> -->
        <!-- <HardAccelSVG  /> -->
        <POIIcon v-else />
      </div>
      <div class="breadcrumb-popup-heading-text">
        <p class="bold heading">{{ heading }}</p>
        <p class="trip-address grey" v-if="dot.data">{{ dot.data.address }}</p>
        <p class="trip-time grey">{{ dot.data ? dot.data.time : "" }}</p>
      </div>
      <div
        v-if="showVideoPlayer"
        style="
          width: 1px;
          height: 64px;
          background-color: var(--light-grey);
          margin: 0 16px;
        "
      />
      <div
        v-if="showVideoPlayer"
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 8px;
        "
      >
        Select View
        <DropDown
          :options="cameraViews"
          :value="cameraViews[0]"
          disabled
          style="width: 120px"
        />
      </div>
    </div>
    <div class="breadcrumb-popup-content">
      <div class="map-popup-text flex" v-if="dot.data && dot.data.odometer">
        <div class="odometer">
          <span
            v-for="(number, index) in dot.data.odometer"
            :key="index"
            class="number"
            >{{
              conversions.getMilesValue(parseInt(number), $i18n.locale)
            }}</span
          >
        </div>
        <span class="grey mi">{{ $t("message.mi") }}</span>
      </div>
      <p
        class="map-popup-text speed-section"
        v-if="dot.data && dot.data.speed && dot.data.speedLimit"
      >
        <span
          :class="[
            'speed',
            dot.data.speedLimit >= dot.data.speed ? 'green' : 'red',
          ]"
          >{{ conversions.getMphValue(dot.data.speed, $i18n.locale) }}</span
        >/<span>{{
          conversions.getMphValue(dot.data.speedLimit, $i18n.locale) +
          " " +
          $t("message.mphLimit")
        }}</span>
      </p>
      <div
        class="launch-video"
        v-if="dot.data && dot.data.video && !showVideoPlayer"
        @click="showVideoPlayer = !showVideoPlayer"
      >
        <img src="@/assets/Video_71_.png" />
        Launch Video
      </div>
    </div>
  </div>
</template>

<script>
import DropDown from "@/components/demo/DropDown.vue";
import TripHistoryVideoPlayer from "@/components/demo/TripHistoryVideoPlayer.vue";
// import HardBrakingSVG from "@/assets/trip-history/markers/hardbrake.svg";
// import HardAccelSVG from "@/assets/trip-history/markers/hardaccel.svg";
import POIIcon from "@/assets/poiicon.svg";
import CloseIcon from "@/assets/close.svg";
import * as conversions from "../../utils/conversions.js";
import BaseIcon from '@/components/icons/BaseIcon.vue';

export default {
  components: {
    DropDown,
    TripHistoryVideoPlayer,
    // HardBrakingSVG,
    // HardAccelSVG,
    POIIcon,
    CloseIcon,
    BaseIcon,
  },
  props: {
    dot: Object,
  },
  data() {
    return {
      showVideoPlayer: false,
      conversions: conversions,
    };
  },
  computed: {
    heading() {
      if (this.dot.type === 1) {
        return this.$t("message.hardBrake");
      } else if (this.dot.type === 2) {
        return this.$t("message.hardAcceleration");
      } else if (this.dot.type === 6) {
        if (this.dot.data.subtype === 0) {
          return this.$t("message.foodAndDrink");
        } else if (this.dot.data.subtype === 1) {
          return this.$t("message.cellphoneUse");
        } else if (this.dot.data.subtype === 2) {
          return this.$t("message.hardBrake");
        } else {
          return this.$t("message.hardAcceleration");
        }
      } else {
        return this.$t("message.tripSnapshot");
      }
    },
    cameraViews() {
      if (this.dot.data.video === "hardbrake3.MOV") return ["Front"];
      return ["In Cabin"];
    },
  },
  methods: {
    calculateHeight() {
      const div = this.$refs.popup;
      // const width = div.offsetWidth;
      const height = div.offsetHeight;
      console.log(height);

      if (this.dot.y > 0) {
        div.style.top = `-${height + 36}px`;
        div.classList.add("above");
      }
      // if (idIsBetween1And9 || idIs205Or206 || idIs53Or54) {
      //   div.style.left = `${width + 36}px`;
      // }
    },
  },
  mounted() {
    this.calculateHeight();
  },
  watch: {
    showVideoPlayer() {
      this.$nextTick(() => {
        this.calculateHeight();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.map-popup {
  display: flex;
  align-items: center;

  width: fit-content;
  background: var(--white);
  border-radius: 0.25rem;
  box-shadow: rgba(23, 28, 38, 0.2) 0px 1px 5px 0px,
    rgba(23, 28, 38, 0.14) 0px 2px 2px 0px,
    rgba(23, 28, 38, 0.12) 0px 3px 1px -2px;

  justify-content: space-between;
  height: auto;
  min-width: 150px;

  .icon-container {
    width: 40%;
  }
  .map-popup-text {
    display: flex;
    flex-direction: column;
    width: 65%;
    .grey {
      color: var(--grey);
    }
  }
}
.breadcrumb-popup {
  position: relative;
  left: -70px;
  top: -1px;
  width: fit-content;
  min-width: 219px;
  padding: 1rem;

  .breadcrumb-popup-heading {
    &.has-border {
      padding-bottom: 10px;
      border-bottom: 1px solid var(--light-grey);
    }
    width: 100%;
    // height: 54px;
    gap: 0.75rem;
    align-items: center;
  }

  .breadcrumb-popup-heading-icon {
    width: 46px;
    aspect-ratio: 1 / 1;
    overflow: hidden;
    .hard-brake-svg,
    .hard-accel-svg,
    .camera-svg {
      border-width: 0.35rem;
      border-style: solid;
      border-radius: 50%;
      padding: 0.4rem;
      // margin-top: 3px;
    }
    .hard-brake-svg {
      border-color: rgb(255, 167, 37);
    }
    .hard-accel-svg {
      border-color: rgb(186, 104, 200);
    }
    .camera-svg {
      border-color: rgb(95, 30, 236);
    }
  }

  .breadcrumb-popup-heading-text {
    .heading {
      font-size: 1.2rem;
    }
    p {
      font-size: 0.85rem;
      margin: 0;
    }
    .trip-address {
      width: fit-content;
    }
  }

  .breadcrumb-popup-content {
    width: 100%;
  }

  .map-popup-text {
    flex-direction: row;
    align-items: center;
  }

  .odometer {
    border: 1px solid var(--light-grey);
    border-radius: 25px;
    width: fit-content;
    margin: 10px 5px 0px 0;
    display: flex;
    align-items: center;

    .number {
      border-right: 1px solid var(--light-grey);
      font-weight: bold;
      padding: 0.1rem 0.3rem;
      text-align: center;

      &:first-child {
        padding-left: 0.5rem;
      }

      &:last-child {
        padding-right: 0.5rem;
        border: 0;
      }
    }
  }
  .mi {
    margin-top: 10px;
  }

  .grey {
    color: var(--grey);
  }

  .speed-section {
    width: 100%;
    span {
      &:first-child {
        padding-right: 3px;
      }
      &:last-child {
        padding-left: 3px;
      }
    }
  }

  .close-icon-container {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    svg {
      height: 1.2rem;
      width: 1.2rem;
    }
  }
  //this before pseudoelement makes the little white arrow attached to the popup
  &::before,
  &::after {
    content: "";
    left: 61px;
    position: absolute;
    border-style: solid;
    border-width: 5px 14px 5px 0;
    border-color: rgba(0, 0, 0, 100) #f3f3f3 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0) white rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  }
  &::before {
    top: -10px;
    transform: rotate(90deg);
    display: block;
  }
  &::after {
    bottom: -10px;
    transform: rotate(270deg);
    display: none;
  }
  &.type1::before,
  &.type1::after,
  &.type2::before,
  &.type2::after {
    left: 81px;
  }
  &.type6::before,
  &.type6::after {
    left: 75px;
  }

  &.above {
    top: -244px;
    &::before {
      display: none;
    }
    &::after {
      display: block;
    }
  }
  &.left {
    left: -186px;
  }

  //do not move above "above" and "left"
  @media screen and (max-width: 1112px) {
    left: -30px;
    &::before,
    &::after {
      left: 25px;
    }
    &.type1::before,
    &.type1::after,
    &.type2::before,
    &.type2::after {
      left: 41px;
    }
    &.type6::before,
    &.type6::after {
      left: 34px;
    }
  }
}
.dot {
  position: absolute;
  top: 0;
  left: 0;
  margin: -12px 0 0 -12px;
  width: fit-content;
  height: auto;
  z-index: 0;
  padding: 0;
}
.red {
  color: var(--red);
}
.orange {
  color: var(--orange);
}
.green {
  color: var(--green);
}
.launch-video {
  margin: 4px 0 0 0;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: var(--blue);
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  img {
    width: 14px;
    margin-top: 1px;
    // filter: invert(30%) sepia(100%) saturate(1022%) hue-rotate(180deg) brightness(95%) contrast(108%);
    filter: invert(30%) sepia(100%) saturate(433%) hue-rotate(180deg)
      brightness(95%) contrast(108%);
  }
}
</style>
